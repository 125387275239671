<div class="flex flex-col md:flex-row md:justify-between md:items-center mt-4" (click)="closeFilters()">
  <div class="flex items-center space-x-4" >
    <input
      type="text"
      placeholder="Número de orden"
      class="border border-gray-300 rounded-lg p-2 w-64 mb-4"
      (change)="filter()"
      (keyup)="filter()"
      [(ngModel)]="searchText"
    />


    <!-- <label class="flex items-center space-x-2 text-gray-500">
      <input type="checkbox" />
      <span>Aplicar filtros a la búsqueda</span>
    </label> -->
  </div>
  <!-- Download Button -->
  <div>
    <button class="bg-blue-500 border-blue-500 text-white px-4 py-2 rounded-lg" (click)="goToNewPayment()">
      + Nuevo Pago
    </button>
  </div>

</div>


<div class="xl:flex xl:justify-between">
  <div class="xl:flex  xl:space-x-2 mt-4 mb-4">
    <div class="mb-4" [ngClass]="{ relative: canShowBankList }">
      <!-- Dropdown Button -->
      <button [class]="getClass(1)" (click)="closeAll(1)" class="min-w-[190px] w-full lg:w-[unset] xl:min-w-[unset]">
        <span class="text-gray-700">Banco de origen</span>
        <svg
          class="w-4 h-4 ml-2 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <app-filter-details
        [data]="bankList"
        [type]="'bank'"
        *ngIf="canShowBankList"
      ></app-filter-details>
    </div>

    <div class="mb-4" [ngClass]="{ relative: canShowlistOfPayers }">
      <button [class]="getClass(2)" (click)="closeAll(2)" class="min-w-[190px] w-full lg:w-[unset] xl:min-w-[unset]">
        <span class="text-gray-700"> Emisor</span>
        <svg
          class="w-4 h-4 ml-2 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <app-filter-details
        [data]="transmitterList"
        [type]="'transmitterList'"
        *ngIf="canShowlistOfPayers"
      ></app-filter-details>
    </div>
    <div class="mb-4" [ngClass]="{ relative: canShowlistOfBeneficiaries }">
      <button [class]="getClass(3)" (click)="closeAll(3)" class="min-w-[190px] w-full lg:w-[unset] xl:min-w-[unset]">
        <span class="text-gray-700"> Receptor</span>
        <svg
          class="w-4 h-4 ml-2 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <app-filter-details
        [data]="receiverList"
        [type]="'receiverList'"
        *ngIf="canShowlistOfBeneficiaries"
      ></app-filter-details>
    </div>
    <div class="mb-4" [ngClass]="{ relative: canShowListOfSteps }">
      <button [class]="getClass(4)" (click)="closeAll(4)" class="min-w-[190px] w-full lg:w-[unset] xl:min-w-[unset]">
        <span class="text-gray-700"> Etapa</span>
        <svg
          class="w-4 h-4 ml-2 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <app-filter-details
        [data]="stepsList"
        [type]="'step'"
        *ngIf="canShowListOfSteps"
      ></app-filter-details>
    </div>

    <div class="mb-4" [ngClass]="{ relative: canshowListOfStatus }">
      <button [class]="getClass(5)" (click)="closeAll(5)" class="min-w-[190px] w-full lg:w-[unset] xl:min-w-[unset]">
        <span class="text-gray-700">Status</span>
        <svg
          class="w-4 h-4 ml-2 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <app-filter-details
        [data]="statusList"
        [type]="'status'"
        *ngIf="canshowListOfStatus"
      ></app-filter-details>
    </div>

    <div class="mb-4" [ngClass]="{ relative: canshowListOfSigneture }">
      <button [class]="getClass(7)" (click)="closeAll(7)" class="min-w-[190px] w-full lg:w-[unset] xl:min-w-[unset]">
        <span class="text-gray-700">Pagos</span>
        <svg
          class="w-4 h-4 ml-2 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <app-filter-details
        [data]="signatureList"
        [type]="'signature'"
        *ngIf="canshowListOfSigneture"
      ></app-filter-details>
    </div>

    <div class="mb-4" [ngClass]="{ relative: canshowListOfOrder }">
      <button [class]="getClass(6)" (click)="closeAll(6)" class="min-w-[190px] w-full lg:w-[unset] xl:min-w-[unset]">
        <span class="text-gray-700">Ordenar por</span>
        <svg
          class="w-4 h-4 ml-2 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <app-filter-details
        [data]="orderList"
        [type]="'order'"
        *ngIf="canshowListOfOrder"
        (click)="closeAll(6)"
      ></app-filter-details>
    </div>

    <div class="mb-4" [ngClass]="{ relative: canshowListOfOrder }">
      <button [class]="getClass(8)" (click)="closeAll(8)" class="min-w-[190px] w-full lg:w-[unset] xl:min-w-[unset]">
        <span class="text-gray-700">Tipo de Gasto</span>
        <svg
          class="w-4 h-4 ml-2 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <app-filter-details
        [data]="_user.expensesList"
        [type]="'expenses'"
        *ngIf="canshowListOfTypeExpenses"
        (click)="closeAll(8)"
      ></app-filter-details>
    </div>


    <div class="mb-4">
      <input
        style="background: rgb(243 244 246)"
        id="demo-external"
        (onSet)="filterRange()"
        [(ngModel)]="selectedRange"
        mbsc-range
        class="border border-gray-300 rounded-lg p-2 xl:w-[250px] h-[43px] bg-gray-400 shadow-sm"
        [ngClass]="{ 'bg-blue-200': selectedRange != '' }"
        [mbsc-options]="dateSetting"
        #externalRange="mobiscroll"
        title="Clear"
        placeholder="Fecha creación"
      />
      <i class="fas fa-times close-icon ml-[-30px] cursor-pointer" aria-hidden="true" (click)="clearRange()"></i>
    </div>
  </div>

  <div class="mb-4 ">
    <select
      (change)="onChangeLimit($event)"
      class="border border-gray-300 rounded-lg p-2 w-40 mt-4 bg-gray-100 min-w-[190px] xl:min-w-[unset]"
    >
      <option [value]="10" [selected]="10 === limit">10</option>
      <option [value]="20" [selected]="20 === limit">20</option>
      <option [value]="50" [selected]="50 === limit">50</option>
      <option [value]="100" [selected]="100 === limit">100</option>
      <option [value]="200" [selected]="200 === limit">200</option>
      <option [value]="500" [selected]="500 === limit">500</option>
      <option [value]="1000" [selected]="1000 === limit">
        1000
      </option></select
    ><br />
    <div class="pagination-info mt-2 mb-2">
      <div>Página {{ page + 1 }} de {{ totalPages }}</div>
      <div>Total Registros: {{ totalRows }}</div>
    </div>
  </div>

</div>
