import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

import {
  mobiscroll,
  MbscDatetimeOptions,
  MbscFormOptions,
} from '@mobiscroll/angular';
import { Router } from '@angular/router';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};
const now = new Date().setHours(0, 0, 0, 0);
let week = [
  new Date().setHours(0, 0, 0, 0),
  new Date().setHours(23, 59, 59, 999),
];

@Component({
  selector: 'app-filters-for-expandable',
  templateUrl: './filters-for-expandable.component.html',
  styleUrls: ['./filters-for-expandable.component.css'],
})
export class FiltersForExpandableComponent implements OnInit {
  canShowBankList = false;
  canShowlistOfPayers = false;
  canShowlistOfBeneficiaries = false;
  canShowListOfCurrency = false;
  canShowListOfSteps = false;
  canshowListOfStatus = false;
  canShowListOfTypeOrder = false;
  showAdvanceDatePicker = false;
  canshowListOfOrder = false;
  canshowListOfSigneture = false;
  canshowListOfTypeExpenses = false;

  btnClass = BTN_CLASS_GROUP[0];
  @Input() bankList: any[] = [];
  currencyList: string[] = ['USD', 'Bs'];
  @Input() stepsList: any[] = [];
  @Input() statusList: any[] = ['Todos', 'Completado', 'Pendiente', 'Anulado'];
  @Input() signatureList: any[] = [
    'Todos',
    'Por firmar',
    'Firmado/Por completar',
  ];

  priorityList: any[] = [];
  filterText: string = '';
  searchText: string = '';
  @Input() data: any[] = [];
  @Input() orderList = ['Fecha de registro', 'Relevancia'];
  @Input() transmitterList: any;
  @Input() receiverList: any;
  @Input() selectedRange: any;
  @Input() dateSetting: any;
  @Input() page: any;
  @Input() totalPages: any;
  @Input() totalRows: any;
  @Input() limit: any;
  constructor(public _user: UserService, private router: Router) {}

  ngOnInit(): void {
    this.bankList = this._user.getBanksData();
    this.stepsList = this._user.stepsList;
    this.transmitterList = this._user.transmitterList;
    this.receiverList = this._user.receiverList;
    console.log(this.bankList);
    this._user.onFilterItemSelected.subscribe((data: any) => {
      this.closeAll();
    });
  }

  closeAll(index = 0) {
    if (index > 0) {
      switch (index) {
        case 1:
          this.canShowlistOfPayers = false;
          this.canShowlistOfBeneficiaries = false;
          this.canShowListOfCurrency = false;
          this.canShowListOfSteps = false;
          this.canshowListOfStatus = false;
          this.canShowListOfTypeOrder = false;
          this.showAdvanceDatePicker = false;
          this.canshowListOfSigneture = false;
          this.canshowListOfTypeExpenses = false;

          this.showBankList();
          break;
        case 2:
          this.canShowBankList = false;
          this.canShowlistOfBeneficiaries = false;
          this.canShowListOfCurrency = false;
          this.canShowListOfSteps = false;
          this.canshowListOfStatus = false;
          this.canShowListOfTypeOrder = false;
          this.showAdvanceDatePicker = false;
          this.canshowListOfSigneture = false;
          this.canshowListOfTypeExpenses = false;

          this.showlistOfPayers();
          break;
        case 3:
          this.canShowBankList = false;
          this.canShowlistOfPayers = false;
          this.canShowListOfCurrency = false;
          this.canShowListOfSteps = false;
          this.canshowListOfStatus = false;
          this.canShowListOfTypeOrder = false;
          this.showAdvanceDatePicker = false;
          this.showlististOfBeneficiaries();
          this.canshowListOfSigneture = false;
          this.canshowListOfTypeExpenses = false;

          break;
        case 4:
          this.canShowBankList = false;
          this.canShowlistOfPayers = false;
          this.canShowlistOfBeneficiaries = false;
          this.canShowListOfCurrency = false;
          this.canshowListOfStatus = false;
          this.canShowListOfTypeOrder = false;
          this.showAdvanceDatePicker = false;
          this.canshowListOfSigneture = false;
          this.canshowListOfTypeExpenses = false;

          this.showlististOfSteps();
          break;
        case 5:
          this.canShowBankList = false;
          this.canShowlistOfPayers = false;
          this.canShowlistOfBeneficiaries = false;
          this.canShowListOfCurrency = false;
          this.canShowListOfSteps = false;
          this.canShowListOfTypeOrder = false;
          this.showAdvanceDatePicker = false;
          this.canshowListOfSigneture = false;
          this.canshowListOfTypeExpenses = false;

          this.showlististOfStatus();
          break;
        case 6:
          this.showlististOfOrder();
          this.canShowBankList = false;
          this.canShowlistOfPayers = false;
          this.canShowlistOfBeneficiaries = false;
          this.canShowListOfCurrency = false;
          this.canShowListOfSteps = false;
          this.canshowListOfStatus = false;
          this.showAdvanceDatePicker = false;
          this.canshowListOfSigneture = false;
          this.canshowListOfTypeExpenses = false;

          break;
        case 7:
          this.canShowBankList = false;
          this.canShowlistOfPayers = false;
          this.canShowlistOfBeneficiaries = false;
          this.canShowListOfCurrency = false;
          this.canShowListOfSteps = false;
          this.canShowListOfTypeOrder = false;
          this.showAdvanceDatePicker = false;
          this.canshowListOfStatus = false;
          this.canshowListOfTypeExpenses = false;

          this.showlististOfSignature();
          break;

        case 8:
          this.canShowBankList = false;
          this.canShowlistOfPayers = false;
          this.canShowlistOfBeneficiaries = false;
          this.canShowListOfCurrency = false;
          this.canShowListOfSteps = false;
          this.canShowListOfTypeOrder = false;
          this.showAdvanceDatePicker = false;
          this.canshowListOfStatus = false;
          this.canshowListOfSigneture = false;
          this.showlististOfExpenses();
          break;
      }
    } else {
      this.canShowBankList = false;
      this.canShowlistOfPayers = false;
      this.canShowlistOfBeneficiaries = false;
      this.canShowListOfCurrency = false;
      this.canShowListOfSteps = false;
      this.canshowListOfStatus = false;
      this.canShowListOfTypeOrder = false;
      this.showAdvanceDatePicker = false;
      this.canshowListOfSigneture = false;
    }
  }

  showBankList() {
    this.canShowBankList = !this.canShowBankList;
  }

  showlistOfPayers() {
    this.canShowlistOfPayers = !this.canShowlistOfPayers;
  }

  showlististOfBeneficiaries() {
    this.canShowlistOfBeneficiaries = !this.canShowlistOfBeneficiaries;
  }

  showlististOfCurrency() {
    this.canShowListOfCurrency = !this.canShowListOfCurrency;
  }

  showlististOfSteps(order = false) {
    this.canShowListOfSteps = !this.canShowListOfSteps;
  }

  showlististOfStatus(order = false) {
    this.canshowListOfStatus = !this.canshowListOfStatus;
  }

  showlististOfSignature(order = false) {
    this.canshowListOfSigneture = !this.canshowListOfSigneture;
  }

  showlististOfExpenses() {
    this.canshowListOfTypeExpenses = !this.canshowListOfTypeExpenses;
  }

  showlististOfOrder() {
    this.canshowListOfOrder = !this.canshowListOfOrder;
  }

  showlististOfTypeOrder() {
    this.canShowListOfTypeOrder = !this.canShowListOfTypeOrder;
  }

  showlAdvanceDatePicker() {
    this.canShowlistOfPayers = !this.showAdvanceDatePicker;
  }

  filter() {
    console.log(this.searchText);
    this._user.onFilterTextChanged.emit(this.searchText);
  }

  showData() {
    this._user.onDownloadData.emit(this.data);
  }

  filterRange() {
    this._user.onRangeChanged.emit(this.selectedRange);
  }

  getClass(index: number) {
    let cssClass = '';
    if (index == 1) {
      this._user.bank_filter == ''
        ? (cssClass = BTN_CLASS_GROUP[0])
        : (cssClass = BTN_CLASS_GROUP[1]);
    }
    if (index == 2) {
      this._user.emisor_filter == ''
        ? (cssClass = BTN_CLASS_GROUP[0])
        : (cssClass = BTN_CLASS_GROUP[1]);
    }
    if (index == 3) {
      this._user.receptor_filter == ''
        ? (cssClass = BTN_CLASS_GROUP[0])
        : (cssClass = BTN_CLASS_GROUP[1]);
    }
    if (index == 4) {
      this._user.selectedResponsable == ''
        ? (cssClass = BTN_CLASS_GROUP[0])
        : (cssClass = BTN_CLASS_GROUP[1]);
    }
    if (index == 5) {
      this._user.selectedStatus == ''
        ? (cssClass = BTN_CLASS_GROUP[0])
        : (cssClass = BTN_CLASS_GROUP[1]);
    }
    if (index == 6) {
      this._user.sort_filter == ''
        ? (cssClass = BTN_CLASS_GROUP[0])
        : (cssClass = BTN_CLASS_GROUP[1]);
    }
    if (index == 7) {
      this._user.selectedSignature == ''
        ? (cssClass = BTN_CLASS_GROUP[0])
        : (cssClass = BTN_CLASS_GROUP[1]);
    }

    if (index == 8) {
      (this._user.type_expenses == '' || this._user.type_expenses == 'Todos')
        ? (cssClass = BTN_CLASS_GROUP[0])
        : (cssClass = BTN_CLASS_GROUP[1]);
    }
    return cssClass;
  }

  clearRange() {
    this.selectedRange = null;
  }

  closeFilters() {
    this._user.onFilterItemSelected.emit({ toClose: true });
  }

  goToNewPayment() {
    this.router.navigate(['/console/order/new']);
  }

  onChangeLimit(event: any) {
    this._user.onChangeLimit.emit(event);
  }
}

const BTN_CLASS_GROUP = [
  'flex items-center px-4 py-2 bg-gray-100 border border-gray-300 rounded shadow-sm',
  'flex items-center px-4 py-2 bg-blue-100 border border-blue-300 rounded shadow-sm',
];
