<!-- Dropdown Menu -->
<div
  class="absolute z-10 mt-2 bg-white border border-gray-200 rounded shadow-lg w-80"
>
  <!-- Search Box -->
  <div
    class="p-2"
    *ngIf="
      type !== 'currency' &&
      type !== 'status' &&
      type !== 'signature' &&
      type !== 'step' &&
      type !== 'order' &&
      type !== 'expenses'
    "
  >
    <input
      [(ngModel)]="filterText"
      (ngModelChange)="filteredItems()"
      type="text"
      placeholder="Search..."
      class="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-200"
    />
  </div>
  <!-- Checkbox List -->
  <ul class="overflow-y-auto max-h-60" *ngIf="auxList.length == 0">
    <li
      class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
      *ngFor="let item of list"
      (click)="selectItem(item)"
    >
      <!-- <input
            type="checkbox"
            class="mr-2 rounded border-gray-300 focus:ring-0"
          /> -->
      {{ item }}
    </li>
  </ul>

  <ul class="overflow-y-auto max-h-60" *ngIf="auxList.length > 0">
    <li
      class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
      *ngFor="let item of auxList"
      (click)="selectItem(item)"
    >
      <!-- <input
            type="checkbox"
            class="mr-2 rounded border-gray-300 focus:ring-0"
          /> -->
      <span>
        {{ item }}
      </span>
    </li>
  </ul>
</div>
